import Head from 'next/head'
import {NextPage, GetStaticProps} from "next";
import {Container, Grid, Box, Typography} from "@mui/material";
import {serverSideTranslations} from "next-i18next/serverSideTranslations";
import {useTranslation} from "next-i18next";
import {HeroLayout} from "../src/components/hero-layout";
import {SampleDestinations} from "../src/components/home/sample-destinations";
import {HomeResponsible} from "../src/components/home/home-responsible";

const Home: NextPage = () => {
    const {t} = useTranslation()
    return (
        <div>
            <Head>
                <title>{t("title-home")}</title>

            </Head>
            <section>
                <Container maxWidth="lg">
                    <Box sx={{mt: 3}}>
                        <Typography variant={"h3"} sx={{fontWeight: 400, lineHeight: 1.1}}
                                    dangerouslySetInnerHTML={{__html: t('home-top-trips-title')}}/>
                    </Box>

                    <Grid
                        container
                        justifyContent={"space-around"}
                    >

                        <Grid item xs={12} md={4} lg={5}>
                            <Typography variant={"body1"} color={"textSecondary"}
                                        dangerouslySetInnerHTML={{__html: t('home-top-trips-description')}}/>
                        </Grid>

                        <Grid item xs={12} md={8} lg={7}>
                            <SampleDestinations/>
                        </Grid>
                    </Grid>

                    <HomeResponsible/>

                </Container>


            </section>
        </div>
    )
}


Home.getLayout = (page) => (
    <HeroLayout>
        {page}
    </HeroLayout>
);

export const getStaticProps: GetStaticProps = async ({locale}) => ({
    props: {
        ...await serverSideTranslations(locale as string, ['frontend']),
    },
})


export default Home
