import React from 'react'
import RegisterIcon from 'mdi-material-ui/CardAccountDetails'
import {useTranslation} from 'react-i18next'
import {useDispatch} from 'react-redux'
import Box from "@mui/material/Box";
import {Hidden, Button, Grid, Typography} from "@mui/material";
import Image from "next/image";
import NextLink from "next-translate-routes/link";
import {imageKitLoader} from "../../lib/imagekit-loader";
import {setShowAuthWall} from "../../slices/user-session";


export const HomeResponsible = () => {

    const {t, i18n} = useTranslation();

    const siteLang = i18n.language


    const dispatch = useDispatch()

    const samples: any = [
        {
            name: {
                en: 'responsible diver in maldives',
                fr: 'plongeuse responsable aux Maldives'
            },
            url: '/images/home/responsible/mobile-responsible1'
        },
        {
            name: {
                en: 'responsible diver looking at a buddha',
                fr: 'plongeur responsable devant un bouddha'
            },
            url: '/images/home/responsible/mobile-responsible2'

        },
        {
            name: {
                en: 'free-diver with a whale shark',
                fr: 'apnéiste avec un requin baleine'
            },
            url: '/images/home/responsible/mobile-responsible3'
        },
        {
            name: {
                en: 'Responsible diving',
                fr: 'Plongée responsable'
            },
            url: '/images/home/responsible/mobile-responsible0'
        }
    ]

    const settings = {
        vertical: false,
        autoplay: true,
        autoplaySpeed: 4000,
        dots: false,
        arrows: false,
        pauseOnHover: true,
        infinite: true,
        speed: 500,
        adaptiveHeight: false,
        slidesToScroll: 1,
        centerMode: false,
        waitForAnimate: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    vertical: false,
                    centerMode: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    vertical: false,
                    slidesToShow: 1,
                }
            },
            {
                breakpoint: 10000,
                settings: {
                    vertical: true,
                    slidesToShow: 1
                },
            }]
    }


    const loginClicked = () => {
        dispatch(setShowAuthWall(true))
    }

    return <>
        <Grid container columnSpacing={2} direction={"row"} alignItems={"end"} sx={{mt: 12}}>
            <Grid item md={4} xl={5} sx={{
                pl: 0,
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={imageKitLoader}
                    width={920}
                    height={520}
                    layout={"responsive"}
                    src="/images/home/responsible/responsible1_920.webp"
                    sizes='20vw'
                    alt={samples[0].name[siteLang]}/>
            </Grid>
            <Grid item md={2} xl={2} sx={{
                px: 0, display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={imageKitLoader}
                    src="/images/home/responsible/responsible0_380.webp"
                    width={380}
                    height={562}
                    layout={"responsive"}
                    sizes='20vw'
                    alt={samples[3].name[siteLang]}/>
            </Grid>
            <Grid item md={6} xl={5} alignSelf={"end"}
                  sx={{pb: 0}}
            >

                <Typography variant={"h3"} sx={{fontWeight: 400, lineHeight: 1.1, mb: 1}}
                            dangerouslySetInnerHTML={{__html: t('with-responsible-divers')}}/>

                <Hidden mdUp>
                    {
                        samples.filter((sample: any, idx: number) => idx === 0).map((sample: any, idx: number) => {
                            return <Box key={idx} style={{position: 'relative'}} my={1}>

                                    <Image
                                        loader={imageKitLoader}
                                        width={1200}
                                        height={800}
                                        layout={"responsive"}
                                        sizes='(min-width: 1200px) 30vw, (min-width: 992px) 50vw, 100vw'
                                        src={`${sample.url}_1200.webp`}
                                        alt={sample.name[siteLang]}
                                    />


                            </Box>
                        })
                    }
                </Hidden>
                <Typography variant={"body1"} color={"textSecondary"}
                            dangerouslySetInnerHTML={{__html: t('responsible-divers-desc')}}/>
            </Grid>
        </Grid>

        <Grid container alignItems={"start"} sx={{pt: 2}} columnSpacing={2}

        >
            <Grid item md={2} xl={2} sx={{
                px: 0,
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={imageKitLoader}
                    width={380}
                    height={570}
                    layout={"responsive"}
                    sizes='20vw'
                    src="/images/home/responsible/responsible2_380.webp"
                    alt={samples[1].name[siteLang]}/>
            </Grid>
            <Grid item
                  md={3} xl={4} sx={{
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                <Image
                    loader={imageKitLoader}
                    width={350}
                    height={237}
                    src="/images/home/responsible/responsible3_350.webp"
                    layout={"responsive"}
                    sizes='20vw'
                    alt={samples[2].name[siteLang]}/>
            </Grid>
            <Grid item
                  xs={false}
                  md={1} sx={{
                display: {
                    xs: "none",
                    md: "block"
                }
            }}
            >
                &nbsp;
            </Grid>
            <Grid item
                  xs={12}
                  md={6} xl={5} sx={{
                textAlign: {
                    xs: "center",
                    md: "left"
                }
            }}
            >
                <NextLink href={"/concept"} passHref>
                    <Button variant="outlined"
                            color="primary"
                            sx={{
                                mt: {
                                    xs: 1,
                                    md: 0
                                }
                            }}
                            title={t('why-responsible-diving-is-important')}
                    >{t('the-concept')}</Button>
                </NextLink>
            </Grid>
        </Grid>


        <Grid container direction={"row"} sx={{mt: 8, mb: 8}}>
            <Grid item
                  xs={12}
                  md={4}
                  alignSelf={"center"}
            >
                <Typography variant={"h3"} sx={{fontWeight: 400, lineHeight: 1.1, mb: 1}}
                            dangerouslySetInnerHTML={{__html: t('meet-new-friends')}}/>

                <Hidden mdUp>
                    <Image
                        loader={imageKitLoader}
                        width={1200}
                        height={578}
                        sizes='80vw'
                        src="/images/home/meet-new-friends_1200.webp"
                        layout={"responsive"}
                        alt={t('meet-new-friends')}/>
                </Hidden>

                <Typography variant={"body1"} color={"textSecondary"}
                            sx={{mt: 1}}
                            dangerouslySetInnerHTML={{__html: t('meet-new-friends-cta')}}/>

                <Button variant="contained"
                        sx={{
                            mt: 4,
                            marginLeft: {
                                xs: "auto",
                                md: 0
                            },
                            marginRight: {
                                xs: "auto",
                                md: 0
                            }
                        }}
                        color="secondary"
                        onClick={loginClicked}
                        startIcon={<RegisterIcon style={{marginBottom: 4}}/>}
                >

                    {t('register')}
                </Button>
            </Grid>
            <Grid item
                  xs={false}
                  md={8} lg={8}
                  sx={{
                      pt: 3,
                      display: {
                          xs: "none",
                          md: "block"
                      }
                  }}

            >
                <Image
                    loader={imageKitLoader}
                    width={1200}
                    height={578}
                    src="/images/home/meet-new-friends_1200.webp"
                    layout={"responsive"}
                    alt={t('meet-new-friends')}/>
            </Grid>
        </Grid>


    </>

}
