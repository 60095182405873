import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import {CardAccountDetails as RegisterIcon} from "mdi-material-ui";
import axios from "axios";
import {UserAvatar} from "../user-avatar";
import {Paper, Typography} from "@mui/material";
import {setShowAuthWall} from "../../slices/user-session";
import {useDispatch} from "../../store";

export const BetaBox = () => {
    const {t, i18n} = useTranslation();

    const [publicProfiles, setPublicProfiles] = useState([]);

    const dispatch = useDispatch();

    const handleLoginClicked = () => {
        dispatch(setShowAuthWall(true));
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(`/api/users/recent`);
                setPublicProfiles(response.data);
            } catch (e) {
                console.error(e);
            }
        };
        fetchData();
    }, []);

    return (
        <Paper
            elevation={8}
            sx={{
                maxWidth: 580,
                margin: "0 auto",
                mt: 6,
                mb: 6,
                position: "relative",
                minHeight: 130,
                // backgroundColor: "red"
            }}>

            <img src={`/images/home/sign_up_${i18n.language}.svg`} width='86' height='87'
                 style={{
                     position: "absolute",
                     left: -1,
                     top: -3
                 }} alt='beta'/>


            <Typography variant={"subtitle1"} color={"text"} component={"div"}
                        sx={{textAlign: "center", pt: {xs: 5,sm: 3}, mb: 1, fontWeight: 400}}>{t("beta-box-title")}</Typography>

            <Grid
                container
                alignItems={"center"}
                spacing={0}
                justifyContent={"center"}
                style={{minHeight: 85}}
                direction={"row"}
            >
                <Grid item sx={{minWidth: 216, minHeight: 93}}>
                    <div style={{paddingLeft: 14}}>
                        {publicProfiles.map((value: any, idx) => {
                            return (
                                <UserAvatar
                                    key={idx}
                                    username={value.username}
                                    photoUrl={value.photoUrl}
                                    homeCountryCode={value.homeCountryCode}
                                />
                            );
                        })}
                    </div>
                </Grid>
                <Grid item>
                    <Button
                        sx={{
                            mx: 2,
                            mb: 3, mt: {
                                xs: 1,
                                sm: 3
                            }
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleLoginClicked}
                        startIcon={<RegisterIcon style={{marginBottom: 4}}/>}
                    >
                        {t("register")}
                    </Button>
                </Grid>
            </Grid>
        </Paper>
    );
}
