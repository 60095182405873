import {useTranslation} from "react-i18next";
import sampleDestinations from "../../generated/sample-destinations.json"
import tripDatabase from "../../generated/trip-database.json";
import Image from "next/image";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import React, {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import Hidden from "@mui/material/Hidden";
import SwipeableViews from "react-swipeable-views";
import useWidth from "../../hooks/use-width";
import {imageKitLoader} from "../../lib/imagekit-loader";
import NextLink from "next-translate-routes/link";
import {useLocale} from "../../hooks/use-locale";
import {Typography} from "@mui/material";

const useStyles = makeStyles((theme) =>
    createStyles({
        sampleNav: {
            // backgroundColor: 'rgba(255,0,0,0.3)',
            [theme.breakpoints.down("md")]: {
                display: "none",
            },
            position: "absolute",
            display: "block",
            height: 200,
            width: 300,
            left: -250,
            bottom: 50,
        },
        sampleNavItem: {
            textDecoration: "none",
            fontSize: "1.0rem",
            textAlign: "right",
            display: "block",
            fontStyle: "italic",
            fontWeight: 500,
            lineHeight: "2.0rem",
            paddingRight: 80,
            letterSpacing: -0.25,
            color: "#707070",
            cursor: "pointer",
            transition: "ease all .3s",
            position: "relative",
            "&:after": {
                color: "#fff",
                content: '""',
                position: "absolute",
                bottom: "0.3rem",
                width: 0,
                height: 18,
                margin: "0px 0 0",
                transition: "all 0.4s ease-in-out",
                transitionDuration: "0.4",
                opacity: 0,
                left: 235,
                // backgroundColor: 'gray',
                backgroundImage: 'url("/images/home/sample-indicator.svg")',
                backgroundRepeat: "no-repeat",
                // backgroundSize: 'cover',
                backgroundPosition: "right",
            },
            // '& + &:before': {
            //   content: '""',
            //   position: 'absolute',
            //   bottom: '0.8rem',
            //   width: 0,
            //   height: 5,
            //   margin: '5px 0 0',
            //   transition: 'all 0.2s ease-in-out',
            //   transitionDuration: '0.3s',
            //   opacity: 0,
            // }
        },
        sampleNavItemActive: {
            fontSize: "1.2rem",
            letterSpacing: 0.5,
            color: theme.palette.primary.main,
            "&:after": {
                width: 99,
                opacity: 1,
            },
            // '& + &:before': {
            //   width: 120,
            //   opacity: 1
            // }
        },
        slide: {
            [theme.breakpoints.down("md")]: {
                margin: "0 auto",
            },
        },
        sampleMobileTitle: {
            textDecoration: "none",
            fontSize: "1.3rem",
            fontStyle: "italic",
            padding: 8,
            position: "absolute",
            backgroundColor: "rgba(128,128,128,0.5)",
            top: 16,
            left: 0,
            zIndex: 1,
            color: "white",
            "&:hover": {
                color: "white",
                textDecoration: "none",
            },
            [theme.breakpoints.up("md")]: {
                paddingLeft: 70,
            },
        },
        slideImage: {
            maxWidth: "100%",
            height: "auto",
            [theme.breakpoints.down("md")]: {
                margin: "0 auto",
            },
        },
    })
);

export const SampleDestinations = () => {

    const {t, i18n} = useTranslation();

    const locale = useLocale()

    const theme = useTheme();

    const classes = useStyles(theme);

    const ref = useRef<HTMLDivElement>(null);

    const [currentNav, setCurrentNav] = useState(0);

    const [playing, setPlaying] = useState(false);

    const width = useWidth()

    const isMobile = width == "xs"


    useEffect(() => {
        setPlaying(true);
    }, []);

    useEffect(() => {
        let interval: any = null;

        if (playing) {
            interval = setInterval(() => {
                setCurrentNav((currentNav + 1) % sampleDestinations.length);
            }, 3000);
        } else if (!playing) {
            clearInterval(interval);
        }

        return () => clearInterval(interval);
    }, [playing, currentNav]);

    const onNavEnter = (idx: number) => {
        //console.log(`onNavEnter(${idx})`)
        setPlaying(false);
        setCurrentNav(idx);
    };

    const onNavLeave = (idx: number) => {
        //console.log(`onNavLeave(${idx})`)
        setPlaying(true);
    };

    const updateCurrentSlide = (index: number) => {
        if (currentNav !== index) {
            setCurrentNav(index);
        }
    };


    const containerHeight = ref.current ? ref.current.offsetWidth * (2 / 3) : 0;
    // const containerHeight = 0;

    return (
        <div style={{position: "relative"}} ref={ref}>

            <SwipeableViews
                index={currentNav}
                disableLazyLoading={true}
                containerStyle={{height: containerHeight}}
                onChangeIndex={(index, indexLast) => updateCurrentSlide(index)}
                axis={isMobile ? "x" : "y"}
                resistance
            >
                {sampleDestinations.map((sampleTrip, idx) => {

                    // @ts-ignore
                    const sampleName = sampleTrip.sampleName[i18n.language];

                    const tripData = tripDatabase[sampleTrip.tripId as keyof typeof tripDatabase];
                    const slugs = tripData.slugs[locale === "fr" ? "fr" : "en"]
                    return (
                        <div
                            key={idx}
                            style={{position: "relative"}}
                        >
                            <Image src={`/content/trips/${sampleTrip.tripId}/${sampleTrip.imageName}`}
                                   alt={sampleName}
                                   loader={imageKitLoader}
                                   style={{
                                       transition: "opacity 250ms linear",
                                       opacity: idx === currentNav ? 1 : 0
                                   }}
                                   priority
                                   layout={"responsive"}
                                   placeholder={"blur"}
                                   className={classes.slideImage}
                                   blurDataURL={sampleTrip.blurDataURL}
                                   width={1200}
                                   height={800}/>
                            <Hidden lgUp>
                                <NextLink passHref href={{
                                    pathname: '/diving/[country]/[slug]',
                                    query: {country: slugs.countrySlug, slug: slugs.tripSlug}
                                }}
                                >
                                    <Typography component={"a"} className={classes.sampleMobileTitle}>
                                        {sampleName}
                                    </Typography>
                                </NextLink>
                            </Hidden>
                        </div>
                    );
                })}
            </SwipeableViews>
            <div
                style={{
                    height: 2,
                    width: "100%",
                    backgroundColor: "#f6f6f6",
                    position: "absolute",
                    zIndex: 1,
                    top: 0,
                }}
            ></div>

            {containerHeight !== 0 && (
                <div className={classes.sampleNav}>
                    {sampleDestinations.map((sampleTrip, idx) => {

                        const tripData = tripDatabase[sampleTrip.tripId as keyof typeof tripDatabase];
                        const slugs = tripData.slugs[locale === "fr" ? "fr" : "en"]

                        // @ts-ignore
                        const sampleName = sampleTrip.sampleName[i18n.language];

                        return (
                            <NextLink
                                key={idx}
                                passHref href={{
                                pathname: '/diving/[country]/[slug]',
                                query: {country: slugs.countrySlug, slug: slugs.tripSlug}
                            }}>
                            <Typography
                                component={"a"}

                                onMouseEnter={(e) => {
                                    onNavEnter(idx);
                                }}
                                onMouseLeave={(e) => {
                                    onNavLeave(idx);
                                }}
                                className={clsx(classes.sampleNavItem, {
                                    [classes.sampleNavItemActive]: idx === currentNav,
                                })}
                            >
                                {sampleName}
                            </Typography>
                            </NextLink>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
