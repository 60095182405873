import React, {useState, useEffect} from "react";
import {createStyles, makeStyles, useTheme} from "@mui/styles";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";
import Select, {SelectChangeEvent} from "@mui/material/Select";
import {addMonths, format} from "date-fns";
import {enUS} from "date-fns/locale";
import continents from '../generated/continents.json'

import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import Grid from "@mui/material/Grid/Grid";

import FilledInput from "@mui/material/FilledInput";
import InputLabel from "@mui/material/InputLabel";
import LoadingButton from "@mui/lab/LoadingButton";
import {useSelector, useDispatch} from "../store";
import {dateFormat} from "../lib/date-format";
import {setCriteriaContinent, setCriteriaPeriod, getTrips} from "../slices/trips";
import {Box} from "@mui/material";
import NextLink from "next-translate-routes/link";

const useStyles = makeStyles((theme) =>
    createStyles({
        formControl: {
            backgroundColor: "#fff",
            [theme.breakpoints.down("md")]: {
                marginBottom: theme.spacing(3),
            },
        },
        label: {
            margin: "8px 0",
            marginLeft: 10,
            fontFamily: "Neutra Text TF",
            textTransform: "uppercase",
            fontWeight: "bold",
            color: "black",
            fontSize: "1.1rem",
            lineHeight: "1.3rem",
            [theme.breakpoints.down("sm")]: {
                marginLeft: 0,
                fontSize: "1.2rem",
            },
            [theme.breakpoints.down("md")]: {
                marginLeft: 0,
                fontSize: "1.1rem",
            },
        },
        select: {
            width: 290,
            display: "block",
            [theme.breakpoints.down("sm")]: {
                width: 290,
            },
        },
        filledInputRoot: {
            backgroundColor: "#fff",
            height: 60,
            borderRadius: 0,
            // "&:hover": {
            //   backgroundColor: 'yellow'
            // },
        },
        group: {
            marginLeft: theme.spacing(3),
            [theme.breakpoints.down("sm")]: {
                marginLeft: theme.spacing(1),
            },
        },
        formControlLabel: {
            marginBottom: 0,
        },
        button: {
            boxShadow: "none",
            height: 60,
            // width: "90%",
            margin: 0,
            fontSize: "1.5rem",
            // fontSize: "30px",
            [theme.breakpoints.down("xs")]: {
                // fontSize: '1.2rem',
                width: "100%",
                paddingRight: 0,
                paddingLeft: 0,
            },
            [theme.breakpoints.up("sm")]: {
                width: "100%",
                paddingRight: 16,
                paddingLeft: 16,
            },
            [theme.breakpoints.up("md")]: {
                paddingRight: 32,
                paddingLeft: 32,
            },
            [theme.breakpoints.down("md")]: {
                width: 320,
                marginBottom: theme.spacing(2),
            },
            [theme.breakpoints.down("sm")]: {
                width: 290,
            },
            minWidth: 200,
            paddingTop: 8,
            whiteSpace: "nowrap",
            "&:disabled": {
                backgroundColor: "#dc7172",
            },
        },
        formHelperText: {
            marginLeft: 24,
            transform: "translateY(-110%)",
            position: "absolute",
        },
    })
);

export const InitialSearch = () => {

    const theme = useTheme()

    const {t, i18n} = useTranslation();

    const classes = useStyles(theme);

    const {criteria} = useSelector((state) => state.trips);

    const {selectedContinent, selectedPeriod} = criteria

    const selectedContinentSlug = (continents.filter((c: any) => c.continentId == selectedContinent).map((c: any) => c.slug[i18n.language].current))[0] || ""

    const dispatch = useDispatch()

    const handleCriteriaChanged = (e: SelectChangeEvent) => {
        if (e.target.name === "diveContinent") {
            //TODO: re-implement
            const translatedContinent = t(e.target.value);
            // const newPath = `/${match.params[0]}/${translatedContinent}/`.replace(
            //     "//",
            //     "/"
            // );
            // history.replace(newPath + history.location.search);
            dispatch(setCriteriaContinent(e.target.value));
        } else if (e.target.name === "divePeriod") {
            dispatch(setCriteriaPeriod(e.target.value));
            //setQuery({divePeriod: e.target.value});
        }

        dispatch(getTrips({language: i18n.language}));
    };

    //console.log('dive continent', diveContinent)

    const [submitted, setSubmitted] = useState(false);

    let timer = null;

    useEffect(() => {
        setSubmitted(false);
    }, []);


    const handleSubmit = (e: any) => {
        e.preventDefault();

        // const inError = false;
        //
        //
        // if (!inError) {
        //   // gtag("event", "find_your_dive_trip_clicked");
        //
        //   setSubmitted(true);
        //
        //   timer = setTimeout(() => {
        //     const target = `/${siteLang || "en"}/${t("diving-url")}/${
        //       diveContinent ? t(diveContinent) + "/" : ""
        //     }${divePeriod ? "?divePeriod=" + divePeriod : ""}`;
        //     //console.log(`target: ${target}`)
        //     window.location = target.replace("//", "/");
        //   }, 200);
        // }
    };

    return (
        <Box>
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid
                    container
                    direction={"row"}
                    alignItems={"stretch"}
                    wrap={"wrap"}
                    justifyContent={"center"}
                >
                    <Grid item>
                        <FormControl variant={"filled"} className={classes.formControl}>
                            <InputLabel htmlFor="diveContinent" shrink={true}>
                                {t("where-do-you-want-to-go")}
                            </InputLabel>
                            <Select
                                variant={"filled"}
                                disableUnderline
                                autoWidth
                                native
                                onChange={handleCriteriaChanged}
                                value={selectedContinent}
                                input={
                                    <FilledInput
                                        name="diveContinent"
                                        inputProps={{
                                            id: "diveContinent",
                                        }}
                                        classes={{
                                            root: classes.filledInputRoot,
                                        }}
                                    />
                                }
                                className={classes.select}
                            >
                                <option value="">{t("anywhere")}</option>
                                {continents.map((continent: any, idx: number) => {
                                    return <option key={idx}
                                                   value={continent.continentId}>{continent.title[i18n.language]}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl variant={"filled"} className={classes.formControl}>
                            <InputLabel htmlFor="divePeriod" shrink={true}>
                                {t("when-do-you-want-to-go")}
                            </InputLabel>
                            <Select
                                variant={"filled"}
                                disableUnderline
                                autoWidth
                                native
                                value={selectedPeriod}
                                onChange={handleCriteriaChanged}
                                input={
                                    <FilledInput
                                        name="divePeriod"
                                        inputProps={{
                                            id: "divePeriod",
                                        }}
                                        classes={{
                                            root: classes.filledInputRoot,
                                        }}
                                    />
                                }
                                className={classes.select}
                            >
                                <option value="">{t("period-anytime")}</option>

                                {[...Array(36)].map((x, i) => {
                                    const myDate = addMonths(new Date(), i);

                                    return <option key={i}
                                                   value={format(myDate, 'yyyy-MMM', {locale: enUS}).toLowerCase()}>{dateFormat(myDate, 'MMMM yyyy', i18n.language)}</option>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <NextLink href={`/diving/${selectedContinentSlug}`} passHref>
                        <LoadingButton
                            type="submit"
                            variant="contained"
                            color="secondary"
                            loading={submitted}
                            className={classes.button}
                        >
                            &nbsp;{t("initial-search-button")}
                            <KeyboardArrowRight
                                style={{fontSize: 36, margin: 0, paddingBottom: 4}}
                            />
                        </LoadingButton>
                        </NextLink>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
}
